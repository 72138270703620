import * as React from "react"
import Nav from "./nav"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  return (
    <div className="global-wrapper">
      <header className="global-header">
        <Nav title={title} />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout
