import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import LogoSvg from "../images/snorkout-tidal-logo.svg"

const Nav = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        categories(filter: { status: {_eq: "published" }}) {
            title
            slug
        }
      }
    }
  `)

  const navItems = data.directus.categories
  return (
    <nav className="bg-white shadow dark:bg-gray-800">
      <div className="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-center md:justify-between">
            <Link
              className="self-auto"
              to="/"
            >
              <img src={LogoSvg} alt={title} width="244" height="40" />
            </Link>
        </div>
        <div id="md-menu" className="hidden md:block">
          <div className="items-center md:flex">
            <div className="flex flex-row md:mx-6 justify-items-stretch">
              {navItems.map(navItem => {
                return (
                  <Link
                    key={navItem.slug}
                    to={`/category/${navItem.slug}`}
                    className="py-5 text-base font-medium text-gray-700 transition-colors duration-200 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0"
                  >
                    {navItem.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div id="sm-menu" className="md:hidden sm:block">
          <div className="items-center sm:flex">
            <div className="flex flex-row md:mx-6 justify-items-stretch">
              {navItems.map(navItem => {
                return (
                  <Link
                    key={navItem.slug}
                    to={`/category/${navItem.slug}`}
                    className="py-5 text-base text-center font-medium text-gray-700 transition-colors duration-200 transform dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400 md:mx-4 md:my-0 basis-1/3"
                  >
                    {navItem.title}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
