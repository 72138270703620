import * as React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <section className="bg-white">
      <div className="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2">
          <div className="px-5 py-2">
            <Link
              to="/terms-conditions"
              className="text-base leading-6 text-gray-700 hover:text-gray-900"
            >
              Terms &amp; conditions
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/disclaimer"
              className="text-base leading-6 text-gray-700 hover:text-gray-900"
            >
              Disclaimer
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/privacy-policy"
              className="text-base leading-6 text-gray-700 hover:text-gray-900"
            >
              Privacy Policy
            </Link>
          </div>
        </nav>
        <p className="mt-8 text-base leading-6 text-center text-gray-700">
          © 2021 Snorkout. All rights reserved.
        </p>
      </div>
    </section>
  )
}

export default Footer
